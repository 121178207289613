import { getLoggedStatus } from "../helpers";

export const fireMemberRatePresent = (pageType: string, room: any): void => {
  const loggedState = getLoggedStatus();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "memberRatePresent",
    pageType: pageType,
    loggedState: loggedState,
    roomDetails: room,
  });
};
